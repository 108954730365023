.loadingDot {
  font-size: 100px;
  margin-top: -80px;
  position: relative;
  animation: ease-in-out infinite alternate;
  animation-name: run;
  animation-duration: 1.2s;
}

@keyframes run {
  0% {
    left: -90px;
    color: #0000ff;
  }
  50% {
    color: #666;
  }
  100% {
    left: 90px;
    color: #0000ff;
  }
}
