.return-busca-head div {
  background-color: #c3d7ec !important;
  margin-bottom: 2px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.return-busca div {
  background-color: #cce5ff;
  margin-bottom: 2px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
